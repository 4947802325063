<template>
<div class="bounty">
   <NoData v-if="bountyDatalist == '' || bountyDatalist.length == 0" bottomBg="true"></NoData>
    <div class="reward-item" @click="goinfo(item.id,item.status)"  v-for="(item,index) in bountyDatalist" :key="index">
        <div class="reward-center">
            <div class="reward-center-title white-space">{{ item.title }}</div>
            <div class="reward-top">
                <user-info :wechat="item.user_profile && item.user_profile.wechat" :labelimg="item.user_profile && item.user_profile.user_label && item.user_profile.user_label.picture" :vipimg="item.user_profile && item.user_profile.user && item.user_profile.user.level" :captain="item.user_profile && item.user_profile.is_team_master" :captain_s="item.user_profile && item.user_profile.is_group_team_master"></user-info>
                <div class="reward-top-r vertical-center">
                    <img v-if="item.height_bounty" src="~assets/images/index/shang.png"  class="reward-top-r-img" />
                    发布于{{item.create_time}}
                </div>
            </div>
            <div class="reward-center-info white-space-2">
                {{item.content}}
            </div>
        </div>
        <div class="reward-imgs">
            <!-- <img class="reward-imgs-item" v-for="(img,indexs) in item.picture.splice(0,3)"   :key="indexs" :src="img" /> -->
            <img class="reward-imgs-item"   :src="item.picture[0]" v-if="item.picture[0]" />
            <img class="reward-imgs-item"   :src="item.picture[1]" v-if="item.picture[1]" />
            <img class="reward-imgs-item"   :src="item.picture[2]" v-if="item.picture[2]" />
        </div>
        <div class="reward-bottom vertical-center" v-if="type == 'lb'">
            <div class="vertical-center">
                <span class="reward-bottom-t" ><span>{{item.credit}}</span>积分</span>
                <div  class="reward-bottom-solve align-center"  v-if="item.is_solve == 1">
                    已解决
                </div>
                <div class="reward-bottom-solve-a align-center"  v-if="item.is_solve == 0">
                    未解决
                </div>
            </div>
            <div>浏览{{item.browse_num}} · 回答{{item.comment_num}}</div>
        </div>
        <div class="reward-bottom vertical-center" v-if="type != 'lb'">
            <div>
                <span class="reward-bottom-l"  v-if=" type == 'fb' || type == 'cg' || type == 'gb'">{{item.community_categories.name}} <span style="margin: 0 3px">|</span> </span>
                浏览{{$handle.tranNumber( item.browse_num,3)}} · 回答{{$handle.tranNumber( item.comment_num,3)}}
            </div>
            <div v-if="type == 'fb' || type == 'cg'" class="space-between">
                <div @click.stop="fbdel(item.id)" >删除</div> <div style="margin: 0 5px;" v-if="item.is_solve == 0">|</div> <div @click.stop="goCreate(item.id)" v-if="item.is_solve == 0">编辑</div><div style="margin: 0 5px;" v-if="type == 'cg'">|</div> <div @click.stop="gorelease(item.id)" v-if="type == 'cg'">发布</div>
            </div>
            <div v-if="type == 'sc'" class="space-between" @click.stop="collect(item.id)">
                <img class="reward-bottom-img" src="~assets/images/index/star-a.png"   />
            </div>
            <div v-if="type == 'll'" class="space-between" @click.stop="del(item.browse_id)" >
                <img class="reward-bottom-img" src="~assets/images/index/del.png"   />
            </div>
        </div>
    </div>
</div>

</template>

<script>
import UserInfo from "components/userInfo";
import NoData from "components/NoData";
export default {
  name: "bounty",
  components: {UserInfo,NoData},
  props: ['bountyData','type'],
  data() {
    return {
      bountyDatalist: [],
      credit:'',
    }
  },
  created() {
    if (this.bountyData.length) {
      this.bountyDatalist = this.bountyData
    }
  },
  methods: {
     //跳转悬赏详情
 goinfo(id,status){
    if(this.type == 'cg'){
      this.$store.commit('M_ACTS','1')
    }else if(this.type == 'gb'){
      this.$store.commit('M_ACTS','2')
    }else{
      this.$store.commit('M_ACTS','0')
    }
    
     this.$router.push({path: '/community/show',query: {id: id}})
  
  },
 
  //发布删除
  fbdel(id){
      this.$dialog.confirm({
            title: '删除',
            message: '是否删除该悬赏',
          })
            .then(() => {
              // on confirm
            let url = this.$api.CommunityBounty + '/' + id
      this.$http.del(url,{},true).then(res => {
        if(res.data.success){
         this.$toast('删除成功');
          this.$emit('updata', );
        }else{
          this.$toast(res.data.message);
        }
      })
            })
            .catch(() => {
              // on cancel
            });
   
  },
  //删除
  del(id){
    let  url = this.$api.communityBrowse + '/' + id
    this.$http.del(url,{},true).then(res => {
        if (res.data.success) {
         this.$toast('删除成功');
          this.$emit('updata', );
        }
      })
  },
  //编辑
  goCreate(id){
      this.$router.push({path: '/community/create',query: {id: id}})
  },
  gorelease(id){
      let url = this.$api.communitybountypublish + '/' + id
      this.$http.put(url,{},true).then(res => {
        if(res.data.success){
         this.$toast('发布成功');
          this.$emit('updata', );
        }else{
          this.$toast(res.data.message);
        }
      }).catch(error =>{
        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      })
  },
   //收藏
   collect(id){
    let url = this.$api.communityCollect
    let data = {
      model_id:id,
      model_type:'bounty'
    }
    this.$http.post(url, data, true).then(res => {
        if (res.data.success) {
       this.$emit('updata', );
        }else{
          this.$toast(res.data.message);
        }
      })
  },
  },
  watch: {
    bountyData(val, oldval) {
      this.bountyDatalist = val
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;

.bounty {
  background: #f7f7f7;
  .userinfo .userinfo-name{
    font-size: 28/$r;   
    color: #313A43!important;
  }
}

.reward-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20/$r;
  background: #FFFFFF;

   padding: 20/$r 32/$r; 

  .reward-top {
    display: flex;
    justify-content: space-between;

    .reward-top-r {
      font-size: 24/$r;
      font-family: PingFang SC;
      font-weight: 400;
      color: #93999F;

      .reward-top-r-img {
        width: 32/$r;
        height: 32/$r;
        margin-right: 5/$r;
      }
    }
  }

  .reward-center {
    display: flex;
    flex-direction: column;

    .reward-center-title {
      font-size: 32/$r;
      font-family: PingFang SC;
      font-weight: bold;
      color: #313A43;
      margin-bottom: 25/$r;
    }

    .reward-center-info {
      font-size: 28/$r;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      margin: 20/$r 0 30/$r 0;
    }
  }

  .reward-imgs {
    display: flex;

    /* justify-content: space-between; */

    .reward-imgs-item {
      width: 220/$r;
      height: 220/$r;
      margin-right: 10/$r;
      border-radius: 4/$r;
      box-shadow: 0/$r 0/$r 5/$r 0/$r rgba(0, 0, 0, 0.15);
      object-fit: cover;
    }
  }

  .reward-bottom {
    font-size: 24/$r;
    font-family: PingFang SC;
    font-weight: 400;
    color: #93999F;
    margin: 30/$r 0;
    display: flex;
    justify-content: space-between;
    .reward-bottom-t{
        color:#FF0000;
        font-size:24/$r;
        margin-right: 10/$r;
        span{
            font-size: 36/$r
        }
    }
    .reward-bottom-solve{
width: 100/$r;height: 40/$r;background: #FF6B29;border-radius: 6/$r;font-size: 24/$r;font-weight: 500;color: #FFFFFF;
    }
    .reward-bottom-solve-a{
        width: 100/$r;height: 40/$r;background: #BFBFBF;border-radius: 6/$r;font-size: 24/$r;font-weight: 500;color: #FFFFFF;
    }
    .reward-bottom-l{
        color: #FF6B29;margin-right: 10/$r;
    }
    .reward-bottom-img{
width: 28/$r;height: 28/$r;
    }
  }

  .reward-bottom-a {
    font-size: 24/$r;
    font-family: PingFang SC;
    font-weight: 400;
    color: #93999F;
    margin: 30/$r 0;
  }
}

/* .reward-item .reward-imgs::after{
  content: '';
  flex: 1;
} */



</style>
<style lang="scss">
$r: 750/16/1rem;

// .bounty {
//   .userinfo .userinfo-name{
//     font-size: 28/$r!important;   
//     color: #313A43!important;
//   }
// }
</style>